<script setup lang="ts">

import { ref } from 'vue'

const isOpen = ref(false)

</script>

<template>
    <div class="dropdown" :class="{'show' : isOpen}">
        <div @click="isOpen = !isOpen">
            <slot name="button" />
        </div>
        <div class="dropdown-menu" :class="{'show' : isOpen}">
            <slot />
        </div>
    </div>
</template>

<style scoped lang="scss">
div.dropdown-menu {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate3d(-90px, 29px, 0px);
}
</style>